import React, { useEffect, useState } from "react";
import { Button } from "../button";
import { history } from "src/stores";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect } from "wagmi";
import { DesktopMenuModal } from "./desktop-menu";
import { MobileMenu } from "./mobile-menu";
import { mataverseHost } from "src/config";

export const Header = (props: any) => {
  const { setShowTrailer, setShowComingSoonModal } = props;
  const [showBg, setShowBg] = useState(false);
  const [openDesktopMenu, setOpenDesktopMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const isMobile = window?.screen?.width < 1024;

  useEffect(() => {
    setShowBg(false);
    if (window.location.pathname === "/map") setShowBg(true);
    if (window.location.pathname === "/land-licenses") setShowBg(true);
  }, []);

  const connect = () => {
    open();
  };

  const logout = () => {
    disconnect();
  };

  const redirect = (
    url: string,
    type: "url" | "page",
    self: boolean = false
  ) => {
    if (type === "url") return window.open(url, self ? "_self" : "_blank");
    if (type === "page") return history.push(url);
  };

  return (
    <div
      className={`fixed top-0 left-0 w-[100%] flex flex-row z-10 py-[30px] px-[30px] items-center z-[500] ${
        showBg ? "header-bg" : "no-bg"
      }`}
    >
      <div className="flex flex-row items-center">
        {/* Logo */}
        <img
          src="/assets/Giga_Logo_2140.svg"
          alt="logo"
          className="cursor-pointer w-[164px]"
          onClick={() => history.push("/")}
        />
        <p className="desktop uppercase text-white font-[500] text-[10px] pl-[30px]">
          LEAVE YOUR MARK ON GALAXIES
        </p>
      </div>

      {/* Buttons & Menu */}
      <div className="flex flex-row items-center justify-end flex-1">
        <div className="desktop">
          <Button icon="airdrop" action={() => redirect("/airdrop", "page")} />
        </div>

        <div className="ml-[10px] desktop">
          <Button icon="map" action={() => redirect("/map", "page")} />
        </div>

        <div className="ml-[10px] desktop">
          <Button
            icon="metaverse"
            action={() =>
              mataverseHost
                ? window.open(mataverseHost)
                : setShowComingSoonModal(true)
            }
          />
        </div>

        {!address && (
          <div className="ml-[10px] desktop">
            <Button icon="metamask" action={() => connect()} />
          </div>
        )}

        <div className="flex flex-row items-center">
          {address && (
            <div className="ml-[10px] desktop">
              <Button icon="logout" action={() => logout()} />
            </div>
          )}

          {address && (
            <div className="ml-[10px]">
              <Button
                icon="profile"
                action={() => redirect("/land-licenses", "page")}
              />
            </div>
          )}
        </div>

        <div className="ml-[10px] ">
          <Button
            icon="menu"
            action={() => {
              !isMobile && setOpenDesktopMenu(true);
              isMobile && setOpenMobileMenu(true);
            }}
          />
        </div>
      </div>

      <DesktopMenuModal
        isModalOpen={openDesktopMenu}
        setIsModalOpen={setOpenDesktopMenu}
        setShowTrailer={setShowTrailer}
        setShowComingSoonModal={setShowComingSoonModal}
      />

      {isMobile && (
        <MobileMenu
          isMenuOpen={openMobileMenu}
          setIsMenuOpen={setOpenMobileMenu}
          setShowTrailer={setShowTrailer}
          setShowComingSoonModal={setShowComingSoonModal}
        />
      )}
    </div>
  );
};

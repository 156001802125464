import {
  EthereumClient,
  w3mProvider,
  w3mConnectors,
} from "@web3modal/ethereum";
import { configureChains, createConfig } from "wagmi";
import { goerli, mainnet } from "wagmi/chains";
require("dotenv").config();

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export const NETWORK = process.env.REACT_APP_NETWORK;
export const API_URL = process.env.REACT_APP_API_URL;

export const infuraId = process.env.REACT_APP_INFURA_ID;

// Web3 Modal
export const projectId = "bbdad8334bf7190ebdac1c35573a98ed";
const chains = [mainnet, goerli];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// Wagmi client
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiConfig, chains);

export const openseaUrl: string =
  NETWORK === "mainnet"
    ? "https://opensea.io/assets/ethereum/0x3eec6cd0654e847171207b4b352320ea6c583360"
    : "https://testnets.opensea.io/assets/goerli/0x88F44772a600b0f3B99BeFA499CeeBc94c22Ca64";

export const masterWhitelist: String[] = [
  "0xB421EfAF07AcaeE4f90913E6935AD2dEdd25f4b3",
  "0x334343E01DF7d3E60dAd996078a05a383E7FeaDe",
  "0xD53f61917ED99CB327a8CC20bC9E2b46bc60B620",
  "0xc97910C2088F69cE9207C8CA0873046A0cF06555",
  "0x43941Bbb4e29e7413c853484ADE0CfC1fB83Fce8",
  "0x7938d79352Ef0F58Cd2058F930629928aE74B790",
  "0x229366BC7ffFadE060928D8a637DB43a473dcFa6",
  "0xCc349270eCB0e7f1f279bFec9fF302654Ff3D85D",
];

export const getAcceptedTokens = (account: string) => {
  if (NETWORK === "mainnet") {
    return ["eth", "gmt", "umad"];
  }

  if (NETWORK !== "mainnet") {
    return ["eth", "tier", "faucet"];
  }

  return [];
};

export const landPrice: any =
  NETWORK === "mainnet"
    ? {
        "1": {
          eth: 0.1,
          gmt: 120,
          umad: 20000,
        },
        "3": {
          eth: 1,
          gmt: 1200,
          umad: 200000,
        },
        "6": {
          eth: 100,
          gmt: 300000,
          umad: 40000000,
        },
        "12": {
          eth: 100,
          gmt: 300000,
          umad: 40000000,
        },
        "24": {
          eth: 100,
          gmt: 300000,
          umad: 40000000,
        },
      }
    : {
        "1": {
          eth: 0.01,
          tier: 10000,
          faucet: 100,
        },
        "3": {
          eth: 0.03,
          tier: 30000,
          faucet: 300,
        },
        "6": {
          eth: 0.06,
          tier: 60000,
          faucet: 600,
        },
        "12": {
          eth: 0.12,
          tier: 120000,
          faucet: 1200,
        },
        "24": {
          eth: 0.24,
          tier: 240000,
          faucet: 2400,
        },
      };

export const getTokenDp = (token: string) => {
  switch (token) {
    case "gmt":
    case "umad":
      return 8;
    default:
      return 18;
  }
};

export const tokenContract: any = {
  tier: "0x3c8fe44510a213a7a684326b2c70ad5f19cf4cd6",
  faucet: "0xBA62BCfcAaFc6622853cca2BE6Ac7d845BC0f2Dc",
  gmt: "0xe3c408BD53c31C085a1746AF401A4042954ff740",
  umad: "0x31c2415c946928e9FD1Af83cdFA38d3eDBD4326f",
};

export const eid: any = {
  tier: {
    "1": 1,
    "3": 2,
    "6": 3,
    "12": 4,
    "24": 5,
  },
  faucet: {
    "1": 6,
    "3": 7,
    "6": 8,
    "12": 9,
    "24": 10,
  },
  gmt: {
    "1": 1,
    "3": 2,
    "6": 3,
    "12": 4,
    "24": 5,
  },
  umad: {
    "1": 6,
    "3": 7,
    "6": 8,
    "12": 9,
    "24": 10,
  },
};

export const mataverseHost = process.env.REACT_APP_METAVERSE_LINK;
